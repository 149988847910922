import React from 'react'

const Productskeleton = () => {
  return (
    <div className="sk mb-4">
<div className="skeleton skeleton-image">
</div>
<div className="skeleton skeleton-text-sm mt-3">
  
</div>
<div className="skeleton skeleton-text mt-3">
  
</div>
<div className="skeleton skeleton-text mt-1">
  
</div>
</div>
  )
}

export default Productskeleton
